.footer {
  margin-top: 30px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.footer-para {
  color: #000000;
  font-weight: 500;
  font-size: 1.8rem;
}

.footer-para p {
  cursor: pointer;
  margin-bottom: 0px;
}

.footer-para p:first-child {
  margin-right: 50px;
}

@media (max-width: 760px) {
  .footer {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: start;
  }

  .social-icons {
    margin-bottom: 20px;
  }

  .footer-para p:first-child {
    margin-right: 20px;
  }
}
