.icon-wrapper {
  background-color: hsla(0, 90%, 84%, 0.33);
  margin-right: 12px;
  width: 5.3rem;
  height: 5.3rem;
  border-radius: 50%;
}

.icon-wrapper img {
  width: 2.8rem;
  height: auto;
}
