@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 10px;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 1120px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 760px) {
  html {
    font-size: 7.5px;
  }
}
