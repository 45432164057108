.nav-wrapper {
  position: relative;
  height: 71px;
  z-index: 1;
  margin-top: 10px;
}

.nav {
  top: 0px;
  left: 100px;
  right: 100px;
  height: 71px;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
}

.nav-items {
  list-style: none;
  margin-bottom: 0px;
}

.nav-items li {
  font-weight: 600;
  font-size: 1.8rem;
  color: #20283a;
  cursor: pointer;
  transition: all 0.5s;
}

.nav-items li:hover {
  background-color: #115dd6;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.menu {
  width: 32px;
  height: auto;
  display: none;
  cursor: pointer;
  z-index: 2;
}

.mobile-nav {
  position: fixed;
  padding-bottom: 40px;
  right: 0px;
  left: 0px;
  top: -1000px;
  background-color: #fff;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.1);
  text-align: center;
  z-index: 1;
  transition: all 0.3s ease-in-out;

  ul {
    flex-direction: column;
    color: #000;
    padding-left: 0;

    li {
      padding: 20px;
      font-size: 2.5rem;
    }

    li:hover {
      background-color: #fff;
      color: #20283a;
      padding: 20px;
    }
  }

  .reg-btn {
    display: block;
  }
}

.about-us-text {
  font-size: 1.6rem;
}

.modal {
  font-family: "Montserrat", sans-serif;
}

.modal-content {
  padding: 0 15px;
}

.modal-header {
  font-family: "Montserrat", sans-serif;
  color: #343a40;
  border-bottom: none;
}

.modal-title {
  font-size: 2.5rem;
  color: #343a40;
  font-weight: 600;
}

.modal-body {
  padding: 0.5rem;
  background-color: #ffffff;
  color: #495057;
}

.modal-body .container.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000;
}

.contact-text {
  word-wrap: break-word;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 1300px) {
  .nav {
    left: 40px;
    right: 40px;
  }
}

@media (max-width: 970px) {
  .nav {
    left: 0px;
    right: 0px;
    padding: 10px;
  }
}

@media (max-width: 760px) {
  .menu {
    display: block;
  }

  .reg-btn {
    display: none;
  }

  .nav-list {
    display: none;
  }

  .nav {
    background-color: #ffffff;
    position: fixed;
  }

  .mobile-nav.show {
    display: block;
    top: 70px;
  }

  .modal-dialog {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
