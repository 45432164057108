.form-component {
  width: 35rem;
}

.form {
  width: 35rem;
}

form.form input.form-input {
  background-color: #f3f1f1;
  padding: 18px 28px;
  margin-bottom: 12px;
  font-size: 1.8rem;
  outline: none;
  border: none;
  border-radius: 2.2rem;
}

form input.form-input:active {
  outline: none;
  border: none;
  background-color: #f3f1f1;
}

form input.form-input:focus {
  background-color: #f3f1f1;
}

.alert-box {
  margin-top: 10px;
}

.alert-message {
  color: #a6abb4;
  font-weight: 400;
  text-align: left;
  font-size: 2.2rem;
}

.alert-dismissible .btn-close {
  top: 3px;
  right: 5px;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  color: #155724;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.success-message .message-icon {
  margin-bottom: 10px;
}

.success-message .message-icon svg {
  width: 32px;
  height: 32px;
  color: #155724;
}

.success-message h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.success-message p {
  font-size: 1.4rem;
  margin: 0;
}

.success-message .close-button {
  padding: 5px 10px;
  background-color: #155724;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.success-message .close-button:hover {
  background-color: #0b3d30;
}

@media screen and (max-width: 1020px) {
  .form {
    width: 33rem;
  }
}

@media screen and (max-width: 830px) {
  .form {
    width: 31rem;
  }
}

@media (max-width: 760px) {
  .form {
    width: 100%;
  }

  .form-component {
    width: 100%;
  }
}
