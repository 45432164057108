.vendor-form-items {
  height: fit-content;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
}

.services-wrapper {
  height: 3rem;
  cursor: pointer;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.3s;
  border: 1px solid #a6abb4;
  padding: 7px 12px;
  gap: 6px;
}

.selected {
  background-color: #6f86f7;
  color: #fff;
}

.underline-style {
  border-bottom: 1px solid #333;
}
