/* Hide the default calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: unset;
  background-image: url("../../assets/Calendar.svg");
}

/* Basic styling for the input field */
input[type="date"] {
  padding: 8px;
  padding-right: 40px; /* Space for the custom icon */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
}

/* Custom calendar icon */
.date-picker {
  position: relative;
  display: inline-block;
}

.date-picker .custom-calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-size: cover;
  pointer-events: none; /* Make the icon unclickable */
}

/* Focus styling for the input field */
input[type="date"]:focus {
  border-color: #007bff; /* Change the border color on focus */
  outline: none;
}
