.toast-body {
  font-size: 1.4rem;
}

.toast-header-title {
  font-size: 1.4rem;
}

.btn-close {
  width: 0.8rem;
  height: 0.8rem;
}
