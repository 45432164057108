.timeline {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #e9ecef;
  content: "";
  left: 20px;
  margin-right: -1.5px;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
}
.timeline .timeline-panel {
  position: relative;
  width: calc(100% - 70px);
  margin-left: 25px;
  padding-left: 30px;
  text-align: left;
}
.timeline .timeline-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}
.timeline .timeline-icon i {
  position: relative;
  top: 2px;
}
.timeline h5 {
  margin-top: 0;
  color: #343a40;
}
.timeline p {
  margin-bottom: 0;
  font-size: 1.4rem;
}

.offer-desc {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 24px;
}

.steps-intro {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.steps-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.para-steps {
  font-size: 1.5rem;
}
