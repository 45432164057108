.content {
  padding: 0px 100px;
  position: relative;
  overflow: hidden;
}

.rectangle {
  position: absolute;
  z-index: -1;
  top: -40px;
  right: 0px;
  width: 500px;
  height: 490px;
  border-radius: 25%;
  transform: rotate(80deg);
  background-image: linear-gradient(to right, #fbb3b393 30%, #115cd66e 70%);
  filter: blur(100px);
}

.toast-container {
  bottom: 20px;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .content {
    padding: 0 40px;
  }
}

@media (max-width: 970px) {
  .content {
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  .rectangle {
    width: 300px;
  }
  .content {
    padding: 0 20px;
  }
}
