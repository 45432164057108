.vendor-registration {
  position: relative;
}

.side {
  position: fixed;
  top: 2rem;
  bottom: 2rem;
  width: 51rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-left: -8rem;
  text-align: left;
  padding: 20px 20px 0 40px;
  font-family: "Montserrat", sans-serif;
}

.logo {
  width: 12.6rem;
  height: 5.2rem;
}

.sub-head {
  font-size: 2.4rem;
  font-weight: 600;
  color: #000000;
  margin-top: 6.2rem;
}

.register-reason {
  margin-top: 5rem;
}

.reason-item {
  font-size: 1.6rem;
  margin-bottom: 4rem;
  color: #1a1a1a;
  font-weight: 400;
  display: flex;
  gap: 1.4rem;
}

.reason-items {
  width: 90%;
}

.reason-items p {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.reason-item span {
  width: 70%;
}

.reason-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sn {
  color: #fff;
  background-color: #000000;
  border-radius: 50%;
  margin-bottom: 0;
  width: 2.8rem;
  height: 2.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.main {
  width: 51.6rem;
  margin: 6rem 0 0 61rem;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}

.blue-logo {
  width: 20.5rem;
  height: 6.9rem;
}

.main-head {
  margin-top: 4rem;
  font-size: 3.2rem;
  color: #1a1a1a;
  text-transform: capitalize;
  font-weight: 700;
  color: #1a1a1a;
}

.form-holder {
  width: 51.6rem;
  background-color: #f5f5f5;
  padding: 2rem 5.2rem;
  border-radius: 20px;
}

.form-items {
  margin-bottom: 2.3rem;
}

.sub-para {
  font-size: 1.8rem;
  color: #1a1a1a;
  margin-top: 19px;
  font-weight: 400;
  width: 80%;
}

.bs-text {
  font-size: 1.8rem;
  color: #1a1a1a;
  margin-top: 6px;
  margin-bottom: 2.5rem;
  font-weight: 400;
  width: 80%;
}

.pf-text {
  font-size: 1.8rem;
  color: #1a1a1a;
  margin-top: 6px;
  margin-bottom: 2rem;
  font-weight: 500;
  width: 100%;
}

.form-para {
  background-color: #f5f5f5;
  width: 51rem;
  border-radius: 20px;
}

.form-label {
  font-size: 1.8rem;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 1.4rem;
}

.form-items input,
.form-items select {
  display: block;
  width: 100%;
  height: 5.2rem;
  font-size: 1.8rem;
  background-color: #fff;
  border-radius: 1.4rem;
  padding: 1.1rem 2.2rem;
  box-shadow: 0 2px 10px #00000011;
  outline: none;
  border: none;
}

.service-content {
  display: block;
  height: 23rem;
  overflow-y: scroll;
  font-size: 1.8rem;
  background-color: #fff;
  border-radius: 1.4rem;
  padding: 1.1rem 2.2rem;
  outline: none;
  border: none;
}

.state-content {
  display: block;
  overflow-y: scroll;
  font-size: 1.8rem;
  background-color: #fff;
  border-radius: 1.4rem;
  padding: 1.1rem 2.2rem;
  outline: none;
  border: none;
}

.state-content::-webkit-scrollbar,
.service-content::-webkit-scrollbar {
  width: 12px;
}

.state-content::-webkit-scrollbar-thumb,
.service-content::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
}

.state-content::-webkit-scrollbar-thumb:hover,
.service-content::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
}

.state-content::-webkit-scrollbar-track,
.service-content::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.dropdown {
  position: relative;
  display: block;
}

.form-items select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-items.required .form-label:after {
  content: "*";
  color: #d14343;
  margin-left: 3px;
}

.dropdown::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 1.6rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-items .dropdown select option {
  background-color: #fff;
  width: 421.2rem;
  color: #333;
  padding: 10px;
}

.dropdown select option:hover {
  background-color: #1a1a1a;
  color: #fff;
}

.radio-label {
  gap: 7px;
}

.radio-label:not(:last-child) {
  margin-right: 2.2rem;
}

input[type="radio"].radio-input,
input[type="checkbox"].radio-input {
  -webkit-appearance: none;
  appearance: none;
  width: 2.2rem;
  height: 2.2rem;
  border: 2px solid #007bff;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-left: 5px;
}

input[type="radio"].radio-input:checked::before,
input[type="checkbox"].radio-input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.2rem;
  height: 2.2rem;
  background-color: #007bff;
  border-radius: 3px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-prefix {
  position: absolute;
  border-top-left-radius: 1.4rem;
  border-bottom-left-radius: 1.4rem;
  pointer-events: none;
  color: #888;
  border-left: none;
  padding: 10px;
  background-color: #f0f0f0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.input.instagram-input {
  padding-left: 5.5rem;
}

.travel-para {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.available-days {
  width: 33rem;
  margin-top: 1.6rem;
}

/* Check label */

.check-label {
  font-size: 1.8rem;
  font-weight: 500;
}

.reg-success-icon {
  margin-top: 6rem;
}

.reg-success-heading {
  font-size: 3.2rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 4.8rem 0;
}

.reg-success-para {
  font-size: 1.5rem;
  font-weight: 500;
  color: #1a1a1a;
}

/* Image Uploader styles */

.image-uploader {
  text-align: center;
  margin: 20px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.upload-button {
  display: inline-flex;
  border: 1px dashed #000000;
  border-radius: 20px;
  width: 100%;
  height: 26rem;
  background-color: #f5f5f5;
  color: hsla(0, 0%, 0%, 0.46);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.upload-button img {
  width: 10rem;
  height: 9.3rem;
}

.browse {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1da1f2;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 19px;
  height: 19px;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #d14343;
  color: white;
  border: none;
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
}

.delete-button img {
  width: 12px;
  height: 12px;
}

/* Stepper styles */
.stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7.3rem 0 4rem 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background-color: #fff;
}

.step-number {
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;
  background-color: hsla(230, 89%, 70%, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  font-size: 3rem;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}

.step-name {
  font-size: 14px;
}

.active .step-number {
  background-color: #115dd6;
  color: #fff;
}

.complete .step-number {
  background-color: #115dd6;
  color: #fff;
}

.progress-bar {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  height: 9px;
  z-index: 1;
  background-color: hsla(230, 89%, 70%, 0.32);
}

.progress {
  height: 100%;
  background-color: #115dd6;
  transition: 0.2s ease;
}

.stepper-nav {
  margin-top: 3.5rem;
  margin-bottom: 2.6rem;
}

.step-btn {
  padding: 12px 14px;
  border-radius: 10px;
}

.btn-full {
  background-image: linear-gradient(to bottom right, #fbb3b3 -30%, #334edd);
  padding-left: 4rem;
}

.btn-ghost {
  border: 2px solid #115dd6;
  padding-right: 4rem;
  background-color: #fff;
  color: #000;
}

.step-btn.complete {
  padding: 12px 4rem;
}

.Toastify__toast-body div {
  font-size: 1.6rem;
}

@media screen and (max-width: 1300px) {
  .side {
    margin-left: -20px;
  }
}

@media screen and (max-width: 1200px) {
  .side {
    width: 48rem;
    margin-left: -10px;
    padding: 20px 20px 0 30px;
  }

  .main {
    width: 50.6rem;
    margin: 6rem 0 0 55rem;
  }
}

@media (max-width: 890px) {
  .side {
    width: 41rem;
  }

  .main {
    width: 40.6rem;
    margin: 6rem 0 0 50rem;
  }

  .form-holder {
    width: 40.6rem;
    padding: 2rem 3rem;
  }
}

@media (max-width: 760px) {
  .side {
    display: none;
  }

  .main {
    width: 58.6rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form-holder {
    width: 58.6rem;
    padding: 2rem 3rem;
  }
}

@media (max-width: 490px) {
  .side {
    display: none;
  }

  .main {
    width: 43.6rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form-holder {
    width: 42.6rem;
    padding: 2rem 3rem;
  }
}
