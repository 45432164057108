.form-items input.venue {
  display: block;
  height: 5.2rem;
  width: 100%;
  font-size: 1.6rem;
  margin: 1.2rem 0rem;
  background-color: #fff;
  border-radius: 1.4rem;
  padding: 1.1rem 2.2rem;
  box-shadow: 0 3px 3px #0000001f;
  outline: none;
  border: none;
}

.form-items textarea {
  height: 18rem;
  display: block;
  width: 100%;
  font-size: 1.6rem;
  background-color: #fff;
  border-radius: 1.4rem;
  padding: 1.1rem 2.2rem;
  box-shadow: 0 2px 10px #00000010;
  outline: none;
  border: none;
}

.error-text.venue-error {
  margin-top: -6rem;
  margin-bottom: 1rem;
}

.form-content {
  width: 53.6rem;
  background-color: #f5f5f5;
  padding: 3rem 3.2rem;
  border-radius: 20px;
}

.form-scroll {
  height: 76rem;
  width: 100%;
  overflow-y: scroll;
}

.background {
  padding: 20px;
  border: 1px solid #ccc;
}

.form-div {
  width: 95%;
}

.form-scroll::-webkit-scrollbar {
  width: 10px;
  margin-right: 20px;
}

.form-scroll::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
}

.form-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
}

.form-scroll::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.req-success-heading {
  font-size: 2.6rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 3.2rem 0;
}

.form-items .form-control {
  border: 1px solid #dee2e6;
  height: 4.5rem;
}

input.input.height-4 {
  height: 4.5rem;
}

.form-items .form-control.more-text {
  height: 10rem;
  font-size: 1.4rem;
}

.negotiate-heading {
  font-size: 1.8rem;
  font-weight: 700;
  width: fit-content;
  margin-bottom: 1.6rem;
}

.layout-text {
  font-size: 1.8rem;
  color: #1a1a1a;
  margin-top: 6px;
  margin-bottom: 2.5rem;
  font-weight: 400;
  width: 100%;
}

.form-label.neg-label {
  font-size: 1.4rem;
}

.form-label.opt-label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.4rem;
}

.label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.4rem;
}

.form-items input.neg-input,
.form-items select.neg-input {
  font-size: 1.4rem;
}

@media (max-width: 890px) {
  .form-content {
    width: 40.6rem;
    padding: 2rem 3rem;
  }
}

@media (max-width: 760px) {
  .form-content {
    width: 58.6rem;
    padding: 2rem 3rem;
  }
}

@media (max-width: 490px) {
  .form-content {
    width: 42.6rem;
    padding: 2rem 3rem;
  }
}
