.header {
  margin-top: 10rem;
  font-family: "Montserrat", sans-serif;
}

.hero {
  position: relative;
  margin-right: 80px;
  width: 55rem;
  height: 53rem;
}

.hero-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 52rem;
  height: 50rem;
  border-radius: 50%;
  z-index: -1;
  background-color: #115dd6;
}

.form-wrapper {
  width: 41rem;
}

.form-wrapper h1 {
  text-align: left;
  margin-bottom: 50px;
  font-weight: 800;
  font-size: 3rem;
  color: #1a1a1a;
}

.quickp-desc {
  color: #a6abb4;
  font-weight: 400;
  margin-top: 50px;
  text-align: left;
  font-size: 2.2rem;
}

.hero-wrapper {
  position: relative;
  width: 40rem;
  background-color: #fff;
  height: 52.5rem;
  padding: 32px 28px 28px 28px;
  border-radius: 36px;

  img {
    width: 34rem;
  }
}

.first-img {
  margin-bottom: 28px;
}

.small-img {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  border-radius: 30px;
  width: 24rem;
  height: 18rem;
  background-color: #fff;
}

.small-img img {
  margin-bottom: 0;
  width: 21rem;
  height: 12rem;
}

.one {
  top: 10%;
  right: -35%;
}

.two {
  bottom: 10%;
  right: -35%;
}

@media screen and (max-width: 1020px) {
  .hero {
    width: 50rem;
    height: 48rem;
  }

  .hero-circle {
    width: 47rem;
    height: 45rem;
  }

  .form-wrapper {
    width: 39rem;
  }

  .form {
    width: 33rem;
  }

  .hero-wrapper {
    width: 36rem;
    height: 48.5rem;
    padding: 30px 26px 26px 26px;
    border-radius: 32px;

    img {
      width: 30rem;
    }
  }

  .small-img {
    width: 22rem;
    height: 16rem;
  }

  .small-img img {
    width: 19rem;
    height: 10rem;
  }
}

@media screen and (max-width: 830px) {
  .hero {
    width: 48rem;
    height: 46rem;
  }

  .hero-circle {
    width: 45rem;
    height: 43rem;
  }

  .form-wrapper {
    width: 37rem;
  }

  .form {
    width: 31rem;
  }

  .hero-wrapper {
    width: 34rem;
    height: 46.5rem;
    padding: 28px 24px 24px 24px;
    border-radius: 32px;

    img {
      width: 28rem;
    }
  }

  .small-img {
    width: 20rem;
    height: 14rem;
  }

  .small-img img {
    width: 17rem;
    height: 8rem;
  }
}

@media (max-width: 760px) {
  .header {
    margin-top: 8rem;
    flex-direction: column;
  }

  .hero {
    margin: 0 auto;
    width: 40rem;
    height: 41rem;
  }

  .hero-circle {
    width: 40rem;
    height: 38rem;
  }

  .form-wrapper {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .hero-wrapper {
    width: 30rem;
    height: 42.5rem;
    padding: 18px 16px 16px 16px;
    border-radius: 32px;

    img {
      width: 24rem;
    }
  }

  .small-img {
    padding: 10px 10px;
    border-radius: 22px;
    width: 18rem;
    height: 12rem;
  }

  .small-img img {
    width: 15rem;
    height: 8rem;
  }

  .two {
    bottom: 20%;
  }
}

@media (max-width: 560px) {
  .header {
    margin-top: 8rem;
    flex-direction: column;
  }

  .hero {
    margin: 0 auto;
    width: 19rem;
    height: 36rem;
  }

  .hero-circle {
    width: 37rem;
    height: 35rem;
  }

  .form-wrapper {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .hero-wrapper {
    width: 25rem;
    height: 35.5rem;
    padding: 18px 16px 16px 16px;
    border-radius: 32px;

    img {
      width: 21rem;
    }
  }

  .small-img {
    padding: 10px 10px;
    border-radius: 22px;
    width: 18rem;
    height: 12rem;
  }

  .small-img img {
    width: 15rem;
    height: 8rem;
  }

  .two {
    bottom: 20%;
  }
}
