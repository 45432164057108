button {
  background-color: #115dd6;
  color: #fff;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 12px 24px;
  outline: none;
  border: none;
  border-radius: 1.8rem;
}

.ghost {
  background-color: transparent;
  border: 2px solid #a6abb4;
  color: #20283a;
}

@media (max-width: 760px) {
  .nav-btn {
    width: 28rem;
  }

  .ghost {
    margin-bottom: 12px;
  }
}
